@font-face {
  font-family: sheikah;
  src: url("576bdfb6fdae2abcf5a755198c8f5cdd.ttf");
}
body {
  background: #000;
  color: #fff;
  background-image: url("a9b27ec789463a30dbc25961bde9a47a.png");
}
ruby {
  font-family: sheikah;
  color: #9ef;
}
ruby rt {
  font-family: arial;
  user-select: none;
}
.textbox {
  padding: 10px;
  line-height: 1.6;
  box-sizing: border-box;
  padding-bottom: 120px;
}
.textbox.translate rt {
  display: block;
}
.textbox ruby {
  animation: shine 3s infinite;
}
.textbox ruby rt {
  display: none;
}
.input-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  display: flex;
  box-sizing: border-box;
  background: rgba(16,45,88,0.9);
}
.input-panel .text-container {
  flex: 1;
  height: 100%;
  border: 5px solid transparent;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.input-panel textarea {
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  color: #fff;
  border: 0;
  outline: 0;
  resize: none;
  background: transparent;
  overflow-y: auto;
  background: #191919;
  background-image: url("a9b27ec789463a30dbc25961bde9a47a.png");
}
.input-panel .settings {
  padding: 10px;
  user-select: none;
}
.input-panel .settings label {
  display: block;
  margin-bottom: 10px;
}
.input-panel .settings label input[type=range] {
  width: 100%;
}
.input-panel .settings ruby {
  font-size: 12px;
  color: #fff;
}
@media screen and (max-width: 640px) {
  .input-panel {
    height: 240px;
    display: block;
  }
  .input-panel .text-container {
    height: 120px;
    flex: auto;
  }
}
@-moz-keyframes shine {
  0% {
    text-shadow: 0 0 1px #fff;
  }
  50% {
    text-shadow: 0 0 3px #fff;
  }
  100% {
    text-shadow: 0 0 1px #fff;
  }
}
@-webkit-keyframes shine {
  0% {
    text-shadow: 0 0 1px #fff;
  }
  50% {
    text-shadow: 0 0 3px #fff;
  }
  100% {
    text-shadow: 0 0 1px #fff;
  }
}
@-o-keyframes shine {
  0% {
    text-shadow: 0 0 1px #fff;
  }
  50% {
    text-shadow: 0 0 3px #fff;
  }
  100% {
    text-shadow: 0 0 1px #fff;
  }
}
@keyframes shine {
  0% {
    text-shadow: 0 0 1px #fff;
  }
  50% {
    text-shadow: 0 0 3px #fff;
  }
  100% {
    text-shadow: 0 0 1px #fff;
  }
}
